//for server
const protocol = "https";
const host = "apis.vruttiitsolutions.com";
const port = "5000";
const trailUrl = "";


// //For Local 
// const protocol = "http";
// const host = "103.86.177.17";
// const port = "5000";
// const trailUrl = "";

// const protocol = "http";
// const host = "103.86.177.17";


// const protocol = "http";

// const host = "192.168.29.135";
// const port = "5000";
// const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

// const hostUrl = `http://192.168.29.134:5000`;
// const endpoint = `http://192.168.29.134:5000`;

const config = {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
export default config;






